import { Slide } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { Page } from 'constants/route';
import { useStore } from 'store';
import React, { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { EAuthStage } from 'store/AuthStore';
import MDBox from 'components/MDBox';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import useAlert from 'utils/Alert';
import MDButton from 'components/MDButton';
import { requestResetPassword } from 'requests/auth/password';
import EmailChecker from './emailChecker';
import PasswordChallenge from './passwordChallenge';
import Verify from '../Verify';
import InitAccount from './initAccount';

const Login = () => {
  const route = useRouter();
  const { e } = route.query;
  const {
    AuthStore: {
      authStage,
      cardSlideDir,
      currentEmailKey,
      tempEmailObj,
      setAuthStage,
      signinEmail,
      checkSandbox,
      resetingPassword,
      setResetingPassword
    }
  } = useStore();
  const [email, setEmail] = useState('');
  const Alert = useAlert();

  useEffect(() => {
    route.prefetch(Page.dashboard);
  }, [route]);

  useEffect(() => {
    if (authStage === EAuthStage.AUTHENTICATED) {
      route.replace(Page.dashboard);
    }
  }, [authStage, route]);

  useEffect(() => {
    if (currentEmailKey) {
      route.replace(Page.Auth.login.concat(`?e=${currentEmailKey}`), undefined, {
        shallow: true
      });
    }
  }, [currentEmailKey, route]);

  useEffect(() => {
    if (e && e !== currentEmailKey) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const email = tempEmailObj[e as string];
      if (
        email &&
        ![
          EAuthStage.AUTHENTICATED,
          EAuthStage.PASSWORD_CHALLENGE,
          EAuthStage.TWO_FA_CHALLENGE
        ].includes(authStage)
      ) {
        setEmail(email);
        setAuthStage(EAuthStage.PASSWORD_CHALLENGE);
      }
    }
  }, [tempEmailObj, route, e, currentEmailKey, authStage, setAuthStage]);

  const slideKey = useMemo(() => {
    switch (authStage) {
      case EAuthStage.IDLE:
      case EAuthStage.CHECK_EMAIL:
        return 'email';
      case EAuthStage.PASSWORD_CHALLENGE:
        return 'password';
      case EAuthStage.INITIALIZING_ACCOUNT:
        return 'init';
      case EAuthStage.TWO_FA_CHALLENGE:
      case EAuthStage.AUTHENTICATED:
        return 'verify';
      default:
        return 'email';
    }
  }, [authStage]);

  const handleTestModeToggle = (ev: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checkSandbox(checked)) {
      Alert.info('You are now running in Test Mode');
      route.reload();
    } else {
      Alert.info('Test Mode deactivated');
      route.reload();
    }
  };

  const handleResetPassword = () => {
    if (authStage === EAuthStage.PASSWORD_CHALLENGE) {
      setResetingPassword(true);
      requestResetPassword(email || signinEmail)
        .then(() => {
          Alert.success('Password reset link sent. please check your email.');
        })
        .catch((err) => {
          Alert.error(err.response?.data?.message || err.message);
        })
        .finally(() => setResetingPassword(false));
    }
  };

  return (
    <>
      <Slide in key={slideKey} unmountOnExit direction={cardSlideDir}>
        <MDBox pb={3} px={3} width="100%">
          {[EAuthStage.IDLE, EAuthStage.CHECK_EMAIL].includes(authStage) && <EmailChecker />}
          {authStage === EAuthStage.PASSWORD_CHALLENGE && (
            <PasswordChallenge email={email || signinEmail} />
          )}
          {authStage === EAuthStage.INITIALIZING_ACCOUNT && <InitAccount />}
          {[EAuthStage.TWO_FA_CHALLENGE, EAuthStage.AUTHENTICATED].includes(authStage) && (
            <Verify {...{ email: email || signinEmail }} />
          )}
        </MDBox>
      </Slide>
      {[EAuthStage.IDLE, EAuthStage.CHECK_EMAIL, EAuthStage.PASSWORD_CHALLENGE].includes(
        authStage
      ) && (
        <>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text" sx={{ userSelect: 'none' }}>
              Forgot password?&nbsp;
              <MDButton
                variant="text"
                color="error"
                form="check-email-form"
                type="submit"
                id="reset-password"
                disabled={resetingPassword}
                onClick={handleResetPassword}
              >
                {resetingPassword ? 'Processing...' : 'Reset Password'}
              </MDButton>
            </MDTypography>
          </MDBox>
        </>
      )}
      {[EAuthStage.IDLE, EAuthStage.CHECK_EMAIL].includes(
        authStage.concat('temp-hide') as EAuthStage
      ) && (
        <>
          <Divider />
          <MDBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: '1rem'
            }}
          >
            <MDTypography
              sx={(theme) => ({
                fontSize: '0.725rem',
                '& code': {
                  color: theme.palette.error.main
                }
              })}
            >
              Switch to&nbsp;
              <code>{checkSandbox() ? 'Live Mode' : 'Test Mode'}</code>
            </MDTypography>
            <Switch
              title="Toggle Test Mode"
              inputProps={{ 'aria-label': 'test mode' }}
              checked={checkSandbox()}
              onChange={handleTestModeToggle}
            />
          </MDBox>
        </>
      )}
    </>
  );
};

export default observer(Login);
