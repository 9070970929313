/* eslint-disable no-param-reassign */
import { useSnackbar, OptionsObject } from 'notistack';
import { useMemo } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useStore } from 'store';

const useAlert = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const {
    AuthStore: { Auth }
  } = useStore();
  const methods = useMemo(
    () => ({
      /**
       * Error Response Body, error message and displayed notification is already being parsed in the error reporting handler
       */
      error: (message: string | null, options: IErrorAlertOptions = {}) => {
        if (!options.metadata) options.metadata = {};
        if (typeof options.report === undefined) options.report = true;
        if (!options.transaction) options.transaction = message || 'unknown';
        const {
          transaction,
          snackbarOptions,
          error,
          metadata,
          hideNotification,
          report,
          severity
        } = options;

        const showPopup = Boolean(message !== null && !hideNotification);
        if (showPopup) enqueueSnackbar(message, { ...snackbarOptions, variant: 'error' });
        if (report)
          Sentry.withScope((scope) => {
            scope.setLevel(severity || Sentry.Severity.Error);
            if (transaction) scope.setTransactionName(transaction.toUpperCase());
            if (Auth.tenant)
              scope.setUser({
                email: Auth.tenant.email,
                id: Auth.tenant.id,
                username: Auth.tenant.companyName
              });
            metadata.displayedNotification = showPopup
              ? message
              : "Notification wasn't shown to user";
            metadata.errorMessage = error.message;
            metadata.resErrorBody = error.response?.data;
            const resErrorString = JSON.stringify(metadata, null, 2);
            scope.setExtra('METADATA', resErrorString);
            if (error instanceof Error) {
              Sentry.captureException(error);
            } else {
              Sentry.captureMessage(message || transaction);
            }
          });
      },

      info: (message: string | null, options: IAlertOptions = {}) => {
        if (!options.metadata) options.metadata = {};
        if (!options.transaction) options.transaction = message || 'unknown';
        const { transaction, snackbarOptions, metadata, hideNotification, report } = options;

        const showPopup = Boolean(message !== null && !hideNotification);
        if (showPopup) enqueueSnackbar(message, { ...snackbarOptions, variant: 'info' });
        if (report)
          Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.Info);
            if (transaction) scope.setTransactionName(transaction.toUpperCase());
            if (Auth.tenant)
              scope.setUser({
                email: Auth.tenant.email,
                id: Auth.tenant.id,
                username: Auth.tenant.companyName
              });
            metadata.displayedNotification = showPopup
              ? message
              : "Notification wasn't shown to user";
            const resErrorString = JSON.stringify(metadata, null, 2);
            scope.setExtra('METADATA', resErrorString);
            Sentry.captureMessage(message || transaction);
          });
      },

      success: (message: string | null, options: IAlertOptions = {}) => {
        if (!options.metadata) options.metadata = {};
        if (!options.transaction) options.transaction = message || 'unknown';
        const { transaction, snackbarOptions, metadata, hideNotification, report } = options;

        const showPopup = Boolean(message !== null && !hideNotification);
        if (showPopup) enqueueSnackbar(message, { ...snackbarOptions, variant: 'success' });
        if (report)
          Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.Debug);
            if (transaction) scope.setTransactionName(transaction.toUpperCase());
            if (Auth.tenant)
              scope.setUser({
                email: Auth.tenant.email,
                id: Auth.tenant.id,
                username: Auth.tenant.companyName
              });
            metadata.displayedNotification = showPopup
              ? message
              : "Notification wasn't shown to user";
            const resErrorString = JSON.stringify(metadata, null, 2);
            scope.setExtra('METADATA', resErrorString);
            Sentry.captureMessage(message || transaction);
          });
      },

      warning: (message: string | null, options: IAlertOptions = {}) => {
        if (!options.metadata) options.metadata = {};
        if (!options.transaction) options.transaction = message || 'unknown';
        const { transaction, snackbarOptions, metadata, hideNotification, report } = options;

        const showPopup = Boolean(message !== null && !hideNotification);
        if (showPopup) enqueueSnackbar(message, { ...snackbarOptions, variant: 'warning' });
        if (report)
          Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.Warning);
            if (transaction) scope.setTransactionName(transaction.toUpperCase());
            if (Auth.tenant)
              scope.setUser({
                email: Auth.tenant.email,
                id: Auth.tenant.id,
                username: Auth.tenant.companyName
              });
            metadata.displayedNotification = showPopup
              ? message
              : "Notification wasn't shown to user";
            const resErrorString = JSON.stringify(metadata, null, 2);
            scope.setExtra('METADATA', resErrorString);
            Sentry.captureMessage(message || transaction);
          });
      },
      default: (message: string, options?: OptionsObject) =>
        enqueueSnackbar(message, { ...options, variant: 'default' }),
      close: closeSnackbar
    }),
    [Auth.tenant, closeSnackbar, enqueueSnackbar]
  );
  return methods;
};

export default useAlert;

interface IMetadata {
  message?: string;
  suspectedReason?: string;
  moreDetails?: string;
  [x: string]: any;
}
interface IAlertOptions {
  /**
   * Set this flag to `true` to send event report to the app monitoring software
   *
   * @default true for `Alert.error`
   * false for `Alert.info`, `Alert.success`, `Alert.debug` & `Alert.warning`
   */
  report?: boolean;
  /**
   * By default, notification is shown to the user once message(arg1) is not null,
   * setting this flag to `true` will hide notification from user.
   *
   * This is useful if you catch any error which you only want to report without bugging the user.
   */
  hideNotification?: boolean;
  /**
   * Extra data to be sent along with report
   */
  metadata?: IMetadata;

  /**
   * Option targetting the snackbar to customize the look and action.
   */
  snackbarOptions?: OptionsObject;
  /**
   * A consistent name for the event, it help to categorize events on the app monitoring software
   * @default message `(arg1)`
   */
  transaction?: string;
}
interface IErrorAlertOptions extends IAlertOptions {
  severity?: Sentry.Severity;
  /**
   * Error object, advised to be set if severity of event is error
   */
  error?: any;
}
