import { Typography, Stack, useTheme } from '@mui/material';
import { otpInputStyle } from 'domains/authentication/styles';
import OtpInput from 'react-otp-input';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import MDBox from 'components/MDBox';
import typography from 'assets/theme/base/typography';
import MDButton from 'components/MDButton';
import useAlert from 'utils/Alert';
import ResendCounter from './resetCounter';

interface IVerify {
  email: string;
}

const { size } = typography;

const Verify = ({ email }: IVerify) => {
  const [pin, setPin] = useState<string>('');
  const [pinError, setPinError] = useState<string | null>('');
  const {
    AuthStore: { isLoading, errors, verify, login, verificationCodeResent }
  } = useStore();

  const Alert = useAlert();

  useEffect(() => {
    if (verificationCodeResent) {
      Alert.success('Verification code resent');
    }
  }, [Alert, verificationCodeResent]);

  const themeHook = useTheme();

  const handleSubmit = useCallback(() => {
    if (pin?.length < 6) return setPinError('invalid code');
    return verify({ email, code: pin });
  }, [email, pin, verify]);

  useEffect(() => {
    if (pin?.length === 6) {
      handleSubmit();
    }
  }, [handleSubmit, pin, pin.length]);

  const handleChange = (e: string) => {
    setPinError(null);
    setPin(e);
  };

  const resendToken = () => {
    login();
  };

  return (
    <MDBox justifyContent="center">
      <MDBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography fontWeight="600" lineHeight="29px" mt="1rem">
          Enter verfication code
        </Typography>
        <Typography textAlign="center" fontSize={size.md} marginBottom="31px" mt="0.7rem">
          Please enter verfication code sent to your mail to continue
        </Typography>
        <Typography color="red" textAlign="center" height="10px" fontSize="0.9rem">
          {pinError || errors.verify}
        </Typography>
        <Stack py="30px">
          <OtpInput
            value={pin}
            onChange={handleChange}
            inputStyle={otpInputStyle(themeHook, 'input')}
            containerStyle={{ width: '100%' }}
            focusStyle={otpInputStyle(themeHook, 'focus')}
            errorStyle={otpInputStyle(themeHook, 'error')}
            numInputs={6}
            hasErrored={!!errors.verify}
            separator={<span style={{ width: '2rem' }} />}
          />
        </Stack>
        <MDButton
          variant="gradient"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          disabled={!(pin.length === 6) || isLoading.verify}
        >
          {isLoading.verify ? 'Loading...' : 'CONTINUE'}
        </MDButton>
        <ResendCounter onClick={resendToken} />
      </MDBox>
    </MDBox>
  );
};

export default observer(Verify);
