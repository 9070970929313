import * as Yup from 'yup';
/**
 * Form validation rules for Login fields
 */
export const emailValidation = Yup.object()
  .shape({
    email: Yup.string()
      .default('email here')
      .required('Email address is required')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Please enter a valid email'
      )
  })
  .required();

export const LoginSchema = Yup.object()
  .shape({
    password: Yup.string().min(4).max(30).required('Password is required').label('Password')
  })
  .required();
export default LoginSchema;
/**
 * Form validation rules for Change Password fields
 */
export const ChangePasswordValidation = Yup.object().shape({
  old_password: Yup.string().required('Password is required').min(6),
  new_password: Yup.string()
    .nullable()
    .required('You must provide a new password.')
    .min(6, 'Your password must be greater than 6 characters')
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).{6,32}$/,
      'Your new password must contain at least one lowercase, one uppercase, one digit, one special character and must be at least 6 characters'
    )
    .notOneOf(
      [Yup.ref('current_password'), null],
      'new password cannot be the same as old password'
    ),
  confirm_new_password: Yup.string()
    .nullable()
    .required('You must confirm your password.')
    .min(6, 'Your password must be greater than 6 characters')
    .oneOf([Yup.ref('new_password'), null], 'New password and confirm password must match')
});
